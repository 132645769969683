import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TRANSLOCO_LOADER, Translation, TranslocoLoader } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class HttpLoader implements TranslocoLoader {
  constructor(private readonly http: HttpClient, private readonly httpHandler: HttpBackend) {
    this.http = new HttpClient(httpHandler);
  }

  getTranslation(langPath: string) {
    return this.http.get<Translation>(`/assets/i18n/${langPath}.json`);
  }
}

export const translocoLoader = { provide: TRANSLOCO_LOADER, useClass: HttpLoader };
