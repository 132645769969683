import { NgModule } from '@angular/core';
import { TranslationSpinnerComponent } from './translation-spinner/spinner.component';
import { SpinnerModule } from '@Terra/shared/widgets/spinner';
@NgModule({
  imports: [SpinnerModule],
  providers: [],
  declarations: [TranslationSpinnerComponent],
  exports: [TranslationSpinnerComponent]
})
export class SharedTranslateLoaderModule {}
